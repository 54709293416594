polygon {
    &.fill-greyisch
    {
        fill: #f8f9fe;
    }
}
td{
    &.red-bold{
        color: $ortho-red;
        font-weight: 500;
    }
}
i{    &.space-icons{  
        padding-left: 10px;

    }
}